.App {
  text-align: center;
  width: 85%;
  margin: auto;
  padding: 30px 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Great+Vibes');

.main-header {
  text-align: center;
}

.main-body {
  width: 88%;
  margin: auto; 
  max-width: 975px;
}

.header-text {
  font-family: 'Great Vibes', cursive;
  font-size: 48px;
  text-shadow: 2px 2px 2px #aaa;
}

.header-text-mobile {
  display: flex;
  flex-direction: column;
  margin: 0px;
  font-size: 40px;
}

.header-text-span {
  line-height: 44px;
}

.title-image > img {
  width: 100%;
}

.wedding-date {
  font-family: 'Nanum Myeongjo', serif;
}

.title-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.title-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-image {
  max-width: 250px;
  width: 30%;
  padding: 0px 20px;
}

.page-title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.center-cropped {
  max-width: 100%;
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.carousel-container {
  margin: 40px auto 0px auto;
  width: 100%;
  max-width: 825px;
}

/* START Trying to fix weird Carousel glitch */
.carousel { overflow: hidden; }
.carousel-item { overflow: hidden; }

.carousel-inner{
  /*just incase the flash (style above) comes back*/
     background: #000;
}
/* --- END Trying to fix weird Carousel glitch */

.nav-link {
  color: #7f58a7 !important;
}

.nav-item > .active {
  color: #704270 !important;
  font-weight: bold;
}

.our-story-container {
  width: 100%;
  max-width: 825px;
  text-align: center;
  padding: 30px;
  margin: 20px auto;
}

.our-story-h2 {
  margin-bottom: 20px;
}

.our-story-img {
  width: 300px;
  margin-top: 20px;
}

.wedding-party-card {
  display: flex;
  flex-direction: row;
  margin-bottom: 45px;
  align-items: center;
}

.wedding-party-img-container {
  margin: auto 30px auto 0px;
  padding: 15px;
}

.wedding-party-img {
  margin: auto;
  border-radius: 50%;
  width: 200px;
}

.wedding-party-text-container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.wedding-party-container {
  margin: 50px auto;
  max-width: 800px;
}

.wedding-party-role {
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
}

.nav-tabs {
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color) !important;
}

#offcanvasNavbar-expand-sm > div.offcanvas-body {
  display: block !important;
}

.nav-link {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

#offcanvasNavbar-expand-sm > div.offcanvas-body > div {
  flex-wrap: wrap;
}

.page-container {
  width: 100%;
  max-width: 825px;
  text-align: center;
  padding: 0px 30px;
  margin: 20px auto;
}

.hotel-name {
  margin-top: 30px;
}

.hotel-title {
  margin-top: 40px;
}

.megjake-button {
  background-color: #9b81b9;
  color: #fff;
  padding: 15px 25px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
}

.megjake-button:hover {
  color: #fff;
  background-color: #7e62a6;
  cursor: pointer;
}

.megjake-button-secondary {
  color: #7f58a7;
  text-decoration: none;
  font-weight: bold;
}

.megjake-button-secondary:hover {
  cursor: pointer;
  
  color: #704270;
}

.hotel-address {
  font-weight: bold;
  margin-top: 25px;
}

.hotel-phone {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 40px;
}

.accommodations-blurb {
  margin-top: 50px;
}

/* Photobook page */

.photos-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-auto-rows: 10px;
  justify-content: center;
}

.tall {
  margin: 10px;
  grid-row-end: span 32;
  overflow: hidden;
  text-align: center;
  box-shadow: -0px 5px 10px 0px grey;
  display: flex;
  align-items: center;
}

.wide {
  margin: 10px;
  grid-row-end: span 18;
  overflow: hidden;
  text-align: center;
  box-shadow: -0px 5px 10px 0px grey;
  display: flex;
  align-items: center;
}
img.wide {
  max-width: 250px;
  margin: -10px;
}

img.tall {
  max-width: 250px;
  margin: -10px;
}

.footer-container {
  border-top: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 40px;
  margin-top: 50px;
}

.footer-container > p {
  text-align: center;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 270px;               
  background-color:#dee2e6;
  height: 1px;
  margin: 20px 0px;
}

/* END Photobook page */

/* Registry Page */
.registry-container {
  width: 100%;
  max-width: 825px;
  text-align: center;
  padding: 0px 30px;
  margin: 20px auto;
}

.registry-flexbox-container {
  display: flex;
  justify-content: space-evenly;
}

.registry-flexbox-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.registry-logo {
  margin-bottom: 8px;
  height: 100px;
}

.third-logo {
  margin-top: 20px;
}

.registry-link {
  color: #7f58a7;
  font-weight: bold;
  font-size: 18px;
}

.registry-gift-card-title {
  margin: 50px 0px 30px;
}
/* END Registry Page */

/* Omaha Page */
.omaha-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.omaha-title {
  margin: 40px 0px;
}

.omaha-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px 35px;
  max-width: 300px;
}

.omaha-picture {
  width: 150px;
  border-radius: 50%;
  margin: 10px 0px;
}
.omaha-divider-mobile {
  display: none;
}
/* END Omaha Page */

/* RSVP Page */
.rsvp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-dropdown {
  margin: 10px 0px 40px;
  position: static !important;
}
.selectedGuest {
  display: flex;
  flex-direction: column;
}
.custom-dropdown-menu {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 350px;
  overflow: scroll;
}
.background-gray {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
.guest-number-div {
  display: flex;
  align-items: center;
}
.guest-info-container {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.guest-number-input{
  max-width: 60px;
}
fieldset {
  border: 1px solid #9b81b9 !important;
  border-radius: 5px;
  padding: 20px !important;
  margin-top: 30px !important;
}
legend {
  float: none !important;
  font-weight: bold;
  width: auto !important;
  padding: 0px 10px !important;
  font-size: 16px !important;
}
.custom-form-item {
  margin-bottom: 15px !important;
}
.rsvp-button {
  margin: auto;
}
.rsvp-form {
  margin-bottom: 35px;
}
.custom-rsvp-input:focus {
  border-color: #9b81b9 !important;
  box-shadow: 0 0 0 0.25rem rgb(127, 88, 167, .25) !important;
}
.custom-rsvp-input {
  width: 90% !important;
}
.dropdown-item {
  margin: 5px 0px !important;
  white-space: normal !important;
}
.success-div {
  display: flex;
  align-items: center;
}
.success-div > p {
  margin: 0px;
}
.success-div > i {
  margin-left: 10px;
}
.incorrect-info-message {
  margin-top: 40px;
}
/* END RSVP Page */

/* START Schedule Page */
.schedule-section {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  width: 100%;
}
.schedule-section-time {
  min-width: 150px;
  text-align: center;
  margin-right: 40px;
}
.schedule-section-details {
  min-width: 290px;
}
.schedule-divider {
  width: 60%;
  margin: 30px 0px;
  color: #704270;
}
.schedule-footer {
  width: 57%;
}
.time-desktop {
  display: block;
}
.time-mobile {
  display: none;
}
/* END Schedule Page */

@media only screen and (max-width: 575px) {
  .navigation-container {
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 15px;
    z-index: 100;
  }
  .nav-item {
    background-color: #fff;
  }
  .nav-tabs {
    border: none !important;
    --bs-nav-tabs-border-color: #fff;
  }
  .nav-link {
    border: none !important;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none !important;
  }
  a {
    border: none !important;
    border-color: #fff !important;
  }
}

@media only screen and (max-width: 607px) and (min-width: 545px)  {
  .header-text {
    font-size: 42px;
  }
  .title-container {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 545px) {
  .header-text-desktop {
    display: block;
  }
  .header-text-date {
    margin-bottom: 25px;
  }
  .mobile-title-container {
    display: none;
  }
  .title-image {
    display: block;
  }
  
}

@media only screen and (max-width: 545px) {
  .header-text-desktop {
    display: none;
  }
  .title-image {
    width: 100%;
    padding: 0px;
    display: flex;
  }
  .title-image > img {
    align-self: center;
  }
  .header-text-date {
    margin-top: 20px;
  }
  .title-container {
    margin-top: 65px;
  }
  .our-story-container {
    padding: 30px 0px;
  }
  .mobile-title-container {
    display: block;
  }
  .title-image-mobile > img {
    width: 280px;
  }
  .title-image {
    display: none;
  }
  .tall {
    grid-row-end: span 24;
  }
  .wide {
    grid-row-end: span 12;
  }
  .photos-container {
    grid-template-columns: repeat(auto-fill, 150px);
    grid-auto-rows: 10px;
  }
  .custom-dropdown-menu {
    min-width: none !important;
    width: 85% !important;
  }
}

@media only screen and (min-width: 546px) {
  .custom-dropdown-menu {
    min-width: 400px !important;
  }
}

@media only screen and (max-width: 700px) {
  .wedding-party-card {
    flex-direction: column;
  }
  .wedding-party-img {
    width: 120px;
  }
  .wedding-party-text-container {
    width: 100%;
  }
  
}

@media only screen and (max-width: 850px) { 
  // .custom-dropdown-menu {
  //   top: 30% !important;
  // }
}

@media only screen and (max-width: 1000px) { 
  .schedule-divider {
    width: 80%;
  }
}

@media only screen and (max-width: 750px) { 
  .schedule-divider {
    width: 100%;
  }
  .omaha-divider-mobile {
    display: block;
    width: 100%;
  }
  .omaha-item {
    margin-bottom: 15px;
  }
  .registry-flexbox-container {
    flex-direction: column;
    align-items: center;
  }
  .registry-flexbox-item {
    margin: 15px 0px;
  }
  .registry-logo {
    height: auto;
    max-height: 80px;
    width: auto;
  }
  .third-logo {
    margin: 0px;
  }
  .guest-info-container {
    width: 100%;
  }
 }

@media only screen and (max-width: 615px) {
  .schedule-section-time {
    min-width: 0px;
  }
  .schedule-section-details {
    width: auto;
    min-width: 0px;
  }
  .schedule-section {
    grid-template-columns: 40% 60%;
  }
  .time-desktop {
    display: none;
  }
  .time-mobile {
    display: block;
  }
  .schedule-footer {
    width: 85%;
  }
}

@media only screen and (min-width: 750px) {
  .schedule-section {
    width: 80%;
  }
}

@media only screen and (min-width: 1000px) { 
  .schedule-divider {
    width: 80%;
  }
}


